import React, {isValidElement} from "react"

import Container from "react-bootstrap/Container"
import {Jumbotron} from "react-bootstrap"

import styles from "./Header.module.scss"

const Header = ({ imageUrl, Heading, SubHeading }) => (
    <>
        <Jumbotron
            fluid
            className={styles.heading}
            // style={{
            //     backgroundImage: `url("${imageUrl}")`,
            // }}
        >
            <Container>
                <h1>
                    <Heading />
                </h1>
            </Container>
        </Jumbotron>
        {
            SubHeading != "empty" && (
                <Container className={styles["sub-heading"]}>
                <div className="text-muted">
                    <SubHeading />
                </div>
            </Container>
            )
        }
    </>
)

export default Header
