import React from "react"

import { LANGUAGE } from "actions/constants"
import shuttle_bus_icon from "photos/bus4.png"
import bus_icon from "photos/bus5.png"
import mtr_icon from "photos/train2.png"
import ferry_icon from "photos/ferry5.png"
import taxi_icon from "photos/taxi2.png"

export default {
    [LANGUAGE.EN]: [
        {
            Type: () => <>MTR</>,
            Details: () => (
                <>
                    <p>
                        Guests can arrive at Sunny Bay Station (Exit A), switch to public bus DB03P and get off at Discovery Bay North Plaza to reach the hotel.
                    </p>
                    <p>
                        Please <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db03p.pdf" >click here</a> for DB03P Timetable.
                    </p>
                </>
            ),
            link: "",
            Icon: mtr_icon,
        },
        {
            Type: () => <>Discovery Bay Ferry</>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    {/* <p>Extra Ferry Stop at DB North (Pilot Run) from 17 December 2023 onwards. Please <a target="_blank" href="https://cdn-assets.aubergediscoverybay.com/assets/44408556-02c4-42db-b8f5-3ccd163235eb">Click Here</a> to see the details.</p> */}
                    <p>Cruise to Discovery Bay Plaza from Hong Kong Central Pier No. 3 in 25 minutes via a high-speed ferry. Change to bus 4 or 9, get off from Siena Two (Joyful Mansion) or DB North Plaza, to arrive at Hotel.</p>
                    <p><a target="_blank" href="https://www.visitdiscoverybay.com/assets/images/transportation_ferry_04_from_central.png">Ferry Schedule - Departure from Central </a></p>
                    <p><a target="_blank" href="https://www.visitdiscoverybay.com/assets/images/transportation_ferry_03_schedule_from_db.png">Ferry Schedule - Departure from Discovery Bay </a></p>
                </>
            ),
            link:
                "",
            Icon: ferry_icon,
        },
        {
            Type: () => <>Discovery Bay Buses<br></br>(Alight at DB North Bus Station)</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>[Direct - <b>Sunny Bay Station</b> to <b>DB North</b>] <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db03p.pdf">DB03P</a></p>
                    <p>[Direct - <b>Tung Chung Station</b> to <b>DB North</b> ] <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db01a.pdf">DB01A</a></p>
                    <p>[From <b> Sunny Bay Station</b> To <b>DB Plaza Bus Terminus</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db03r.pdf">DB03R</a></p>
                    <p>[From <b>DB Plaza Bus Terminus</b> To <b>DB North</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_int_4.pdf">4</a></p>
                    <p>[From <b>DB Plaza Bus Terminus</b> To <b>DB North</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_int_9.pdf">9</a></p>
                    <p>[From <b>DB Plaza Bus Terminus</b> To <b>DB North</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_int_9a.pdf">9A</a></p>
                    <p>[Overnight - <b>DB North</b> to <b>Macau Ferry Bus Terminus</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db08r.pdf">DB08R</a></p>
                </>
            ),
            link:
                "",
            Icon: bus_icon,
        },
        {
            Type: () => <>Taxi</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>Urban taxis (red) and Lantau taxis (blue) can access the Hotel directly.</p>
                </>
            ),
            link: "",
            Icon: taxi_icon,
        },
        {
            Type: () => <>City Shuttle Bus</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>We will be arranging shuttle bus to/from Hotel</p>
                    <ol>
                        <li><b>[07:00PM]</b> From Sunny Bay Station (Exit A) to Hotel</li>
                        <li><b>[10:30PM]</b> From the Hotel to Sunny Bay Station (Exit A)</li>
                        <li><b>[11:00PM]</b> From the Hotel to Sunny Bay Station (Exit A)</li>
                    </ol>
                </>
            ),
            link: "",
            Icon: shuttle_bus_icon,
        },
    ],
    [LANGUAGE.ZH]: [
        {
            Type: () => <>港鐵</>,
            Details: () => (
                <>
                    <p>
                        可以抵達欣澳站（A出口），轉乘公共巴士DB03P，在愉景灣北廣場下車即可抵達酒店。
                    </p>
                    <p>
                        請<a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db03p.pdf" >點擊這裡</a>查看DB03P時間表。
                    </p>
                </>
            ),
            link: "",
            Icon: mtr_icon,
        },
        {
            Type: () => <>愉景灣渡輪</>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    {/* <p>DB North渡輪站。<a target="_blank" href="https://cdn-assets.aubergediscoverybay.com/assets/44408556-02c4-42db-b8f5-3ccd163235eb">點擊這裡</a>查看詳細資訊。</p> */}
                    <p>從香港中環碼頭3號乘坐高速渡輪，在25分鐘內抵達愉景灣廣場。轉乘4號或9號巴士，在Siena Two（Joyful Mansion）或DB North Plaza下車即可抵達酒店。</p>
                    <p><a target="_blank" href="https://www.visitdiscoverybay.com/assets/images/transportation_ferry_04_from_central.png">渡輪時間表 - 從中環出發 </a></p>
                    <p><a target="_blank" href="https://www.visitdiscoverybay.com/assets/images/transportation_ferry_03_schedule_from_db.png">渡輪時間表 - 從愉景灣出發 </a></p>
                </>
            ),
            link:"",
            Icon : ferry_icon,
        },
        {
            Type: () => <>愉景灣巴士<br></br>(愉景北商場落(酒店位置))</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>[直達 - <b>欣澳站</b>到<b>愉景北商場(酒店位置)</b>] <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db03p.pdf">DB03P</a></p>
                    <p>[直達 - <b>東涌站巴⼠總站</b>到<b>愉景北商場(酒店位置)</b> ] <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db01a.pdf">DB01A</a></p>
                    <p>[從 <b>欣澳站</b>到<b>愉景廣場巴士總站(這裡轉車)</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db03r.pdf">DB03R</a></p>
                    <p>[從 <b>愉景廣場巴士總站</b>到<b>愉景北商場(酒店位置)</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_int_4.pdf">4</a></p>
                    <p>[從 <b>愉景廣場巴士總站</b>到<b>愉景北商場(酒店位置)</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_int_9.pdf">9</a></p>
                    <p>[從 <b>愉景廣場巴士總站</b>到<b>愉景北商場(酒店位置)</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_int_9a.pdf">9A</a></p>
                    <p>[通宵巴士 - <b>愉景北商場</b>到<b>港澳碼頭巴⼠總站</b> ]: <a target="_blank" href="https://www.visitdiscoverybay.com/assets/documents/transportation_bus_ext_db08r.pdf">DB08R</a></p>
                </>
            ),
            link:
                "",
            Icon: bus_icon,
        },
        {
            Type: () => <>的士</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>城市的士（紅色）和大嶼山的士（藍色）可以直接抵達酒店。</p>
                </>
            ),
            link: "",
            Icon: taxi_icon,
        },
        {
            Type: () => <>往返欣澳站及酒店巴士</>,
            Title: () => <></>,
            Address: null,
            Phone: null,
            Details: () => (
                <>
                    <p>我們將安排往返酒店的接駁巴士</p>
                    <ol>
                        <li><b>[07:00PM]</b> 從欣澳站（A出口）到酒店</li>
                        <li><b>[10:30PM]</b> 從酒店到欣澳站（A出口）</li>
                        <li><b>[11:00PM]</b> 從酒店到欣澳站（A出口）</li>
                    </ol>
                </>
            ),
            link: "",
            Icon: shuttle_bus_icon,
        },
    ],
}
