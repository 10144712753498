import React from "react"
import { Link } from "react-router-dom"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Header: () => <>Please Sign In</>,
        SubmitButton: () => <>Sign In</>,
        SubmitButtonLoading: () => <>Signing In...</>,
        NoAccountPrompt: () => (
            <>
                Don't have an account?{" "}
                <Link to="/auth/signup">Sign up here.</Link>
            </>
        ),
        emailPlaceholder: "Email",
        passwordPlaceholder: "Password",
    },
    [LANGUAGE.ZH]: {
        Header: () => <>請登入</>,
        SubmitButton: () => <>登入</>,
        SubmitButtonLoading: () => <>正在登入...</>,
        NoAccountPrompt: () => (
            <>
                沒有帳戶？{" "}
                <Link to="/auth/signup">在這裡註冊。</Link>
            </>
        ),
        emailPlaceholder: "電子郵件",
        passwordPlaceholder: "密碼",
    },
}
