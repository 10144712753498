import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Transportation</>,
        SubHeading: () => (
            <>
                <b>Private vehicles are not permitted in Discovery Bay!</b>
                <p>Please find the guide below: (details can be found <a href="https://www.aubergediscoverybay.com/en-us/location">here</a>)</p>
            </>
        ),
    },
    [LANGUAGE.ZH]: {
        Heading: () => <>交通</>,
        SubHeading: () => (
            <>
                <h3><b>愉景灣不允許私人車輛進入。</b></h3>
                <p>以下是如何到達的信息。詳細信息可以在<a href="https://www.aubergediscoverybay.com/en-us/location">這裡</a>找到</p>
            </>
        ),
    },
}
