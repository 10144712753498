import React from "react"
import Emoji from "react-emoji-render"
import Octicon, { MarkGithub } from "@primer/octicons-react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Content: () => (
            <>
                {/* <p>
                    Contact us at{" "}
                    <a href="mailto:hola@lledoisalim.com">
                        hola@lledoisalim.com
                    </a>
                    .
                </p> */}
                <p>
                    <Emoji text="Made with love in Hong Kong by Tammy and Roy." />
                </p>
                {/* <p>
                    <a
                        href="https://github.com/salimhamed/wedding-website"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Source code
                    </a>{" "}
                    available on <Octicon icon={MarkGithub} size={11} />
                </p> */}
            </>
        ),
    },
    [LANGUAGE.ZH]: {
        Content: () => (
            <>
                <p>
                    <Emoji text="在香港Tammy和Roy用愛製作的。" />
                </p>
            </>
        ),
    },
}
