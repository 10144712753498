import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        schedule: () => <span>Schedule</span>,
        travel: () => <span>Transportation</span>,
        thingsToDo: () => <span>Things to Do</span>,
        faq: () => <span>FAQs</span>,
        registry: () => <span>Registry</span>,
        rsvp: () => <span>RSVP</span>,
        signOut: () => <span>Sign Out</span>,
        signIn: () => <span>Sign In</span>,
        manageRsvp: () => <span>Manage RSVP</span>,
        TR: "Tammy & Roy"
    },
    [LANGUAGE.ZH]: {
        schedule: () => <span>日程</span>,
        travel: () => <span>交通</span>,
        thingsToDo: () => <span>其他活動</span>,
        faq: () => <span>Preguntes</span>,
        registry: () => <span>Registre</span>,
        rsvp: () => <span>RSVP</span>,
        signOut: () => <span>登出</span>,
        signIn: () => <span>登入</span>,
        manageRsvp: () => <span>管理RSVP</span>,
        TR: "芷彥 & 民山"
    },
}
