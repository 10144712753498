import { signUp, signIn, signOut, fetchUserAttributes, fetchAuthSession,getCurrentUser } from "aws-amplify/auth"
import { APP } from "../actions/constants"
import { getUserItemFromDynamo } from "./dynamo"

export const signUpWithCognito = params => {
    return new Promise((resolve, reject) => {
        signUp(params)
            .then(data => {
                console.log(data)
                resolve(data)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// Auth.signUp(params)

export const signInWithCognito = (username, password) =>
// new Promise((resolve, reject) => {
//     resolve()
// })    
{
    return signIn({
        username,
        password,
    })
}


export const signOutWithCognito = () =>
    // new Promise((resolve, reject) => {
    //     resolve()
    // })
    signOut()

export const isSignedIn = async() => {
    try{
        await getCurrentUser()
        return true
    }
    catch(e){
        return false
    }
    
}

export const currentAuthenticatedUserWithCognito = async (dispatch) => {
    // get user and credentials

    let CognitoUser

    try {
        CognitoUser = await fetchUserAttributes()
        const
            { name, email, "custom:companion": companion }
                = CognitoUser
        console.log(CognitoUser)
        const credentials = await fetchAuthSession()
        console.log(credentials)
        // const {Item} = await getUserItemFromDynamo(credentials)
       const userDetail = new User(name,email,credentials,companion)
        dispatch({
            type: APP.SET.USER_CURRENT,
            payload: {
                username: name,
                name,
                email,
                credentials,
                userDetail,
            },
        })
    } catch (e) {
        // sign out will clear all existing cognito keys from localStorage
        console.log(e)
        await signOutWithCognito()
        CognitoUser = null
    }

    return CognitoUser
}

export class User {
    constructor(name,email,credentials,companion) {
        this.name = name
        this.email = email
        this.username = name
        this.credentials = credentials
        this.companion = []
        console.log(companion,"#!#!@")
        if (companion) {
            companion.split(",").map((_companion, index) => {
                let _c = _companion.trim();
                if (_c != "" ) {
                    this.companion.push(_c)
                }
            })
        }
    }
}