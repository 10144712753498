import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Schedule</>,
        SubHeading: () => (
            <span>
                Here's what to expect during our wedding weekend. We can't wait
                to celebrate with you!
            </span>
        ),
    },
    [LANGUAGE.ZH]: {
        Heading: () => <>日程表</>,
        SubHeading: () => (
            <span>
               下面是我們婚禮週末的預期活動。我們迫不及待要與你一起慶祝！
            </span>
        ),
    },
}
