import React from "react"
import { useCookies } from "react-cookie"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { selectLanguage } from "utilities/cookies"
import { details } from "content/Home"

import styles from "./Details.module.scss"
import Button from 'react-bootstrap/Button';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {APP} from "../../../actions/constants"
import { Route } from "react-router-dom/cjs/react-router-dom.min"

function Details() {
    const [cookies] = useCookies(["language"])

    const { Title, Date, Location, hashtag, locationLink,RespondPlease } = details[
        selectLanguage(cookies)
    ]
    return (
        <section className={styles.section}>
            <Container>
                <Row>
                    <Col className={styles.details}>
                        <h1 className={styles.date}>
                            <Date />
                        </h1>
                        {/* <h2 className={styles.location}>
                            <a
                                href="https://maps.app.goo.gl/47Mbu24XSAPrpA1o8"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Location />
                            </a>
                        </h2> */}
                        {/* <h3 className={styles.hashtag}>
                            <a
                                href={`https://www.instagram.com/explore/tags/${hashtag}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`#${hashtag}`}
                            </a>
                        </h3> */}
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.rsvpButton}>
                        <Link activeClass="active"  to="rsvppage" spy={true} smooth={true} duration={APP.Constants.SCROLL_DURATION}>
                        <Button variant="outline-dark" >RSVP</Button>
                    </Link>
                    </Col>
                </Row>
                {/* <Row><Col><p className={styles.pleserespond}>{RespondPlease}</p></Col></Row> */}
            </Container>
        </section>
    )
}

export default Details
