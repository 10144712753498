import React from "react"
import { Link } from "react-router-dom"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>RSVP</>,
        SubHeadingAuthenticated: ({ user }) => (
            <span>
                Hi <strong>{user.name}</strong>! We can't wait to see you in
                Hong Kong! Use the form below to manage your RSVP.
            </span>
        ),
        SubHeading: () => (
            <span>
                Please use the link we shared to manage the RSVP.
                {/* <Link to="/auth">Sign in</Link> to manage your RSVP. Don't have
                an account? <Link to="/auth/signup">Sign up</Link> now! */}
            </span>
        ),
    },
    [LANGUAGE.ZH]: {
        Heading: () => <>RSVP</>,
        SubHeadingAuthenticated: ({ user }) => (
            <span>
                你好，<strong>{user.name}</strong>！我們迫不及待想在香港見到你！請使用以下表格管理你的RSVP。
            </span>
        ),
        SubHeading: () => (
            <span>
                {/* 請<Link to="/auth">登入</Link>以管理你的RSVP。還沒有帳户？立即<Link to="/auth/signup">註冊</Link>！ */}
                請使用我們提供的鏈接來管理RSVP。
            </span>
        ),
    },
}
