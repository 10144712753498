import React from "react"

import { LANGUAGE } from "actions/constants"

import mahjong_icon from "photos/mahjong2.png"
import walk_around_icon from "photos/walk_around.png"
export default {
    [LANGUAGE.EN]: [
        {
            Title: () => <>Function Room</>,
            Details: () => (
                <>
                    <p>
                        Function room will be opened at 4:00pm, enjoy yourself with mahjong.
                    </p>
                </>
            ),
            link:
                "",
            Icon: mahjong_icon
        },
        {
            Title: () => <>Explore around</>,
            Details: () => (
                <>
                    <p>
                        Discovery bay is a beauitful place for walking around, you wouldn't want to miss out the amazing sea view and mountain view!
                    </p>
                </>
            ),
            link:
                "",
            Icon: walk_around_icon

        },
    ],
    [LANGUAGE.ZH]: [
        {
            Title: () => <>活動室</>,
            Details: () => (
                <>
                    <p>
                    活動室將於下午4點開放，遊戲內有麻將供大家娛樂。
                    </p>
                </>
            ),
            link:
                "",
            Icon: mahjong_icon,
        },
        {
            Title: () => <>盡情探索吧</>,
            Details: () => (
                <>
                    <p>
                    愉景灣是一個充滿異國風情，有不少風景優美的遠足徑及近50個迷人小市鎮和村落的島嶼！
                    </p>
                </>
            ),
            link:
                "",
                Icon: walk_around_icon  
        },
    ],
}
