import get from "lodash/get"

import {
    signUpWithCognito,
    signInWithCognito,
    signOutWithCognito,
    currentAuthenticatedUserWithCognito,
    getItemFromDynamo,
    putItemToDynamo,
    isSignedIn,
} from "services"

import { APP } from "./constants"

export const initializeApp = async dispatch => {
    const queryParameters = new URLSearchParams(window.location.search)
    const signinCode = queryParameters.get("signcode")
    const signedIn = await isSignedIn()
    if (signinCode && !signedIn) {
        await signInWithCognito(`${signinCode}@backings.life`,signinCode)
    }
    await currentAuthenticatedUserWithCognito(dispatch)
}

export const signUp = async (
    { name, email, password },
    setSubmitting,
    setStatus,
    history,
    dispatch
) => {
    try {
        await signUpWithCognito({
            username: email,
            password,
            name: name,
            options: {
                userAttributes: {
                    name,
                    email
                },
            }
        })

        await signInWithCognito(email, password)
        await currentAuthenticatedUserWithCognito(dispatch)
        // dispatch({
        //     type: APP.SET.USER_SIGN_IN,
        //     payload: {
        //         username:name,
        //         name,
        //         email,
        //     },
        // })
    } catch (error) {
        const { message } = error

        setStatus(message)
        dispatch({
            type: APP.SET.USER_ERROR,
            payload: message,
        })
    }

    setSubmitting(false)
}

export const signIn = async (
    { email: providedEmail, password },
    setSubmitting,
    setStatus,
    history,
    dispatch
) => {
    try {
        await signInWithCognito(providedEmail, password)
        await currentAuthenticatedUserWithCognito(dispatch)
        // const 
        // { name,email }
        // = CognitoUser
        // dispatch({
        //     type: APP.SET.USER_SIGN_IN,
        //     payload: {
        //         username:name,
        //         name,
        //         email,
        //     },
        // })
    } catch (error) {
        let { message } = error

        setStatus(message)
        dispatch({
            type: APP.SET.USER_ERROR,
            payload: message,
        })
    }
    setSubmitting(false)
}

export const signOut = async dispatch => {
    try {
        await signOutWithCognito()
        dispatch({
            type: APP.SET.USER_SIGN_OUT,
        })
    } catch (error) {
        const { message } = error
        dispatch({
            type: APP.SET.USER_ERROR,
            payload: message,
        })
    }
}

export const fetchUserRSVPInformation = async (credentials, dispatch) => {
    try {
        const { Item } = await getItemFromDynamo(credentials, {
            domain: "RSVP_CONFIRMATION",
        })

        const record = new Record(Item)
        dispatch({
            type: APP.SET.RSVP,
            payload: {
                record,
            },
        })
    } catch (error) {
        console.error(error.message)
    }
}

export const putUserRSVPInformation = async (
    username,
    credentials,
    reocrd,
    setSubmitting,
    setStatus,
    setShowConfirmation,
    dispatch
) => {
    try {
        const useremail = credentials.tokens.signInDetails.loginId
        await putItemToDynamo(credentials, {
            ...reocrd.ToJson(),
            cognito_userid: credentials.identityId,
            email: useremail.toLowerCase(),
            name: username,
            domain: "RSVP_CONFIRMATION",
            // wedding_guests: weddingGuests,
            // marriage_ceremony_guests: marriageCeremonyGuests,
            // words: reocrd.words,
            // need_bus_to: needBusTo,
            // need_bus_from: needBusFrom,
            // additional_guestnames: additionalGuestnames,
        })
        await fetchUserRSVPInformation(credentials, dispatch)

        setShowConfirmation(true)

        // dismiss alert
        setTimeout(() => setShowConfirmation(false), 3000)
    } catch (error) {
        const { message } = error
        setStatus(message)
    }
    setSubmitting(false)
}

export class Record {
    constructor(item) {
        console.log(item)
        this.words = item && item["words"]
        this.needBusToOption = item && item["need_bus_to_option"] || []
        this.needBusFromOption = item && item["need_bus_from_option"] || []
        this.linkTo = item && item["link_to"]
        // this.join_banquet = item["join_banquet"]
        // this.join_ceremony = item["join_ceremony"]
        // this.join_both = item["join_both"]
        this.joinOption = item && item["join_option"] || []
        this.name = item && item["name"]
        this.cognitoUserid = item && item["cognito_userid"]
        this.email = item && item["email"]
    }

    ToJson() {
        return {
            words: this.words,
            need_bus_to_option: this.needBusToOption,
            need_bus_from_option: this.needBusFromOption,
            linkTo: this.linkTo,
            join_option: this.joinOption,
            name: this.name,
            cognito_userid: this.cognito_userid,
            email: this.email,
        }
    }

}