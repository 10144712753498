import {fetchAuthSession} from 'aws-amplify/auth';
import DynamoDB from "aws-sdk/clients/dynamodb"

const getDocumentClient = async (credentials) => {
    // const credentials = await fetchAuthSession()
    return new DynamoDB.DocumentClient({
                    region: process.env.REACT_APP_REGION,
                    credentials: credentials.credentials,
        })  
}
    // new Promise((resolve, reject) => {
    //     resolve()
    // })
    // fetchAuthSession().then(
    //     credentials =>
    //         new DynamoDB.DocumentClient({
    //             region: process.env.REACT_APP_REGION,
    //             credentials: credentials,
    //         })
    // )

export const putItemToDynamo = async (credentials,Item) => {
    const params = {
        TableName: process.env.REACT_APP_USER_TABLE,
        Item,
    }
    console.log(Item)
    const client = await getDocumentClient(credentials)
    return await client.put(params).promise()
}

export const getItemFromDynamo = async (credentials,Key) => {
    Key["cognito_userid"] = credentials.identityId
    Key["domain"] = "RSVP_CONFIRMATION"
    const params = {
        TableName: process.env.REACT_APP_USER_TABLE,
        Key,
    }

    const client = await getDocumentClient(credentials)
    return await client.get(params).promise()
}

export const getUserItemFromDynamo = async (credentials) => {
    console.log(credentials.identityId)
    const key = {
        cognito_userid: credentials.identityId,
        domain: "USER",
    }
    const params = {
        TableName: process.env.REACT_APP_USER_TABLE,
        Key:key,
    }

    const client = await getDocumentClient(credentials)
    return await client.get(params).promise()
}
