import React from "react"
import { useCookies } from "react-cookie"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { selectLanguage } from "utilities/cookies"
import { schedule, title, details } from "content/Schedule"

// import React from "react"
// import { useCookies } from "react-cookie"
// import Container from "react-bootstrap/Container"

// import { selectLanguage } from "utilities/cookies"
// import { schedule, title } from "content/Schedule"
// import { Header } from "components/Header"
import { Item } from "components/Item"
// import headerImg from "photos/header_small.jpg"

import styles from "./Details.module.scss"

function Details() {
    const [cookies] = useCookies(["language"])
    const scheduleItems = schedule[selectLanguage(cookies)]
    const { Title, Date, Location, hashtag, locationLink } = details[
        selectLanguage(cookies)
    ]
    return (
        <section>
            <Container>
                <Row>
                    <Col className={styles.details}>
                        {/* <h1 className={styles.date}>
                            <Date />
                        </h1> */}
                        {/* <h1 className={styles.location}>
                            <a
                                href="https://maps.app.goo.gl/47Mbu24XSAPrpA1o8"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Location />
                            </a>
                        </h1> */}
                        <h3 className={styles.hashtag}>
                            <a
                                href={`https://www.instagram.com/explore/tags/${hashtag}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* {`#${hashtag}`} */}
                            </a>
                        </h3>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                {scheduleItems.map(
                    (
                        {
                            Title,
                            Date,
                            Time,
                            Location,
                            locationLink,
                            Address,
                            Details,
                            mapLink,
                            Icon
                        },
                        idx
                    ) => (
                        <Item
                            key={idx}
                            LeftMainTitle={Title}
                            LeftFirstSubTitle={Date}
                            LeftSecondSubTitle={Time}
                            RightMainTitle={() => (
                                <a
                                    href={locationLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Location />
                                </a>
                            )}
                            RightFirstContact={() => (
                                <a
                                    href={mapLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Address />
                                </a>
                            )}
                            RightDetails={Details}
                            Icon={Icon}
                        />
                    )
                )}
            </Container>
        </section>
    )



}

export default Details

// return (
//     <>
//         <Header
//             imageUrl={headerImg}
//             Heading={Heading}
//             SubHeading={SubHeading}
//         />
//         <Container fluid>
//             {scheduleItems.map(
//                 (
//                     {
//                         Title,
//                         Date,
//                         Time,
//                         Location,
//                         locationLink,
//                         Address,
//                         Details,
//                         mapLink,
//                     },
//                     idx
//                 ) => (
//                     <Item
//                         key={idx}
//                         LeftMainTitle={Title}
//                         LeftFirstSubTitle={Date}
//                         LeftSecondSubTitle={Time}
//                         RightMainTitle={() => (
//                             <a
//                                 href={locationLink}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                             >
//                                 <Location />
//                             </a>
//                         )}
//                         RightFirstContact={() => (
//                             <a
//                                 href={mapLink}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                             >
//                                 <Address />
//                             </a>
//                         )}
//                         RightDetails={Details}
//                     />
//                 )
//             )}
//         </Container>
//     </>
// )