import React from "react"

import { LANGUAGE } from "actions/constants"
import wedding_ceremony_icon from "photos/wedding_ceremony.png"
import  photo_shooting_icon from "photos/photoshot.png"
import church_icon from "photos/church.png"
import registration_icon from "photos/registration.png"

export default {
    [LANGUAGE.EN]: [
        {
            Title: () => <>Wedding Ceremony</>,
            Date: () => <></>,
            Time: () => <>3:00pm</>,
            Location: () => <>Pavilion</>,
            locationLink:
                "https://www.google.com/maps/place/White+Chapel/@22.3068261,114.0165181,331m/data=!3m1!1e3!4m6!3m5!1s0x3403fdb3b700ea3d:0xe1191e70e982f5bf!8m2!3d22.3065442!4d114.0166581!16s%2Fg%2F11cmf35q32?entry=ttu",
            Address: () => (
                <>
                    <p>Hong Kong, Discovery Bay, HK 92 Siena Avenue</p>
                    <p>Discovery Bay Auberge</p>
                </>
            ),
            mapLink:
                "https://www.google.com/maps/place/White+Chapel/@22.3068261,114.0165181,331m/data=!3m1!1e3!4m6!3m5!1s0x3403fdb3b700ea3d:0xe1191e70e982f5bf!8m2!3d22.3065442!4d114.0166581!16s%2Fg%2F11cmf35q32?entry=ttu",
            Details: () => (
                <>
                    <p>
                        Join us as we exchange vows and celebrate the start of our journey together!
                    </p>
                </>
            ),
            Icon:church_icon,
        },
        {
            Title: () => <>Photo Shooting</>,
            Date: () => <></>,
            Time: () => <>4:00pm</>,
            Location: () => <>Paddock</>,
            locationLink: "https://www.google.com/maps/place/White+Chapel/@22.3068261,114.0165181,331m/data=!3m1!1e3!4m6!3m5!1s0x3403fdb3b700ea3d:0xe1191e70e982f5bf!8m2!3d22.3065442!4d114.0166581!16s%2Fg%2F11cmf35q32?entry=ttu",
            Address: () => null,
            mapLink:
                "",
            Details: () => (
                <>
                    <p>
                        Smiles and snapshots await! Join us for some fun photos!
                    </p>
                </>
            ),
            Icon: photo_shooting_icon
        },
        {
            Title: () => <>Registration</>,
            Date: () => <></>,
            Time: () => <>6:00pm</>,
            Location: () => <>Outside Grand Azure</>,
            locationLink: "https://www.google.com/maps/@22.3070529,114.0169891,92m/data=!3m1!1e3?entry=ttu",
            Address: () => (
               null
            ),
            mapLink:
                "",
            Details: () => (
                <>
                    <p>
                    Leave your mark on our day by registering your presence!
                    </p>
                </>
            ),
            Icon: registration_icon
        },
        {
            Title: () => <>Banquet</>,
            Date: () => <></>,
            Time: () => <>8:00pm</>,
            Location: () => <>Grand Azure</>,
            locationLink: "https://www.google.com/maps/@22.3070529,114.0169891,92m/data=!3m1!1e3?entry=ttu",
            Address: () => (
               null
            ),
            mapLink:
                "",
            Details: () => (
                <>
                    <p>
                    Be our guest and enjoy an evening of celebration with us!
                    <br>
                    </br>
                    For a better experience, preferably arrive before 7:30 PM!
                    </p>
                </>
            ),
            Icon:wedding_ceremony_icon,
        },
    ],
    [LANGUAGE.ZH]: [
        {
            Title: () => <>證婚典禮</>,
            Date: () => <></>,
            Time: () => <>下午三時</>,
            Location: () => <>白色教堂</>,
            locationLink:
                "https://www.google.com/maps/place/White+Chapel/@22.3068261,114.0165181,331m/data=!3m1!1e3!4m6!3m5!1s0x3403fdb3b700ea3d:0xe1191e70e982f5bf!8m2!3d22.3065442!4d114.0166581!16s%2Fg%2F11cmf35q32?entry=ttu",
            Address: () => (
                <>
                    <p>香港，愉景灣，香港92賽馬會道</p>
                    <p>愉景灣酒店</p>
                </>
            ),
            mapLink:
                "https://www.google.com/maps/place/White+Chapel/@22.3068261,114.0165181,331m/data=!3m1!1e3!4m6!3m5!1s0x3403fdb3b700ea3d:0xe1191e70e982f5bf!8m2!3d22.3065442!4d114.0166581!16s%2Fg%2F11cmf35q32?entry=ttu",
            Details: () => (
                <>
                    <p>
                    請和我們一起見證並慶祝我們婚禮！
                    </p>
                </>
            ),
            Icon:church_icon,
        },
        {
            Title: () => <>拍照時間</>,
            Date: () => <></>,
            Time: () => <>下午四時</>,
            Location: () => <>白色教堂外面</>,
            locationLink: "https://www.google.com/maps/place/White+Chapel/@22.3068261,114.0165181,331m/data=!3m1!1e3!4m6!3m5!1s0x3403fdb3b700ea3d:0xe1191e70e982f5bf!8m2!3d22.3065442!4d114.0166581!16s%2Fg%2F11cmf35q32?entry=ttu",
            Address: () => null,
            mapLink:
                "",
            Details: () => (
                <>
                    <p>
                    一起來拍照留念吧！
                    </p>
                </>
            ),
            Icon: photo_shooting_icon
        },
        {
            Title: () => <>恭候</>,
            Date: () => <></>,
            Time: () => <>下午六時</>,
            Location: () => <>Grand Azure</>,
            locationLink: "https://www.google.com/maps/@22.3070529,114.0169891,92m/data=!3m1!1e3?entry=ttu",
            Address: () => (
               null
            ),
            mapLink:
                "",
            Details: () => (
                <>
                    <p>
                    請來簽名並在我們的重要日子留下你的足跡吧！
                    </p>
                </>
            ),
            Icon: registration_icon
        },
        {
            Title: () => <>宴會</>,
            Date: () => <></>,
            Time: () => <>晚上八時</>,
            Location: () => <>Grand Azure</>,
            locationLink: "https://www.google.com/maps/@22.3070529,114.0169891,92m/data=!3m1!1e3?entry=ttu",
            Address: () => (
               null
            ),
            mapLink:
                "",
            Details: () => (
                <>
                    <p>
                    和我們一起享受一個充滿慶祝氣氛的晚上！
                    <br>
                    </br>
                    為了確保更好的體驗，最好在晚上7:30之前到達！
                    </p>
                </>
            ),
            Icon:wedding_ceremony_icon,
        }
    ],
}
