import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <>Things To Do</>,
        SubHeading: () => (
            <>
                Have a good time in Discovery Bay!
            </>
        ),
        link: "",
    },
    [LANGUAGE.ZH]: {
        Heading: () => <>其他活動</>,
        SubHeading: () => (
            <>
                我們很高興能與你分享區域內幾個我們最喜歡的地方。
            </>
        ),
        link: "",
    },
}
