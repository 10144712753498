import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Title: "We're getting married!"
    },
    [LANGUAGE.ZH]: {
        Title: "我們要結婚了！"
    }
}
