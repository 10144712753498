import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        submitButtonText: "Submit RSVP",
        updateButtonText: "Update RSVP",
        yesLabel: "Yes",
        noLabel: "No",
        WeddingFormHeader: () => <p><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i>Banquet<i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i><i className="fa fa-leaf" style={{ "fontSize": "20px" }}></i></p>,
        NumberOfGuestsLabel: () => <>How many guests attending?</>,
        Willyoubeattending: () => <>Will you be joining us?</>,
        JoiningUsOptions: ["Yes! I will be attending both the wedding ceremony and the banquet.",
            "Yes! I will be attending the wedding ceremony.",
            "Yes! I will be attending the banquet.",
            "Unfortunately, I won't be able to attend."],
        zeroLabel: "Can't Attend",
        NumberOfGuestsHelp: () => (
            <>

            </>
        ),
        TransportationToLabel: () => (
            <>We're arranging a shuttle bus to the hotel from <b>Sunny Bay MTR Station</b>. Please let us know your preference!</>
        ),
        TransportationFromLabel: () => (
            <>We're arranging a shuttle bus from the hotel after the banquet to the <b>Sunny Bay MTR Station</b>. Let us know your preference!</>
        ),
        Transportationptions: [
            "I will settle it myself ^^",
            "5:30pm (not available)",
            "6:30pm (not available)",
            "7:00pm"],
        TransportationpFromtions: [
            "I will settle it myself ^^",
            "10:30pm",
            "11:00pm"],
        TransportationToHelp: () => (
            <>
                Schedule and location will be finalised soon.
            </>
        ),
        TransportationFromHelp: () => (
            <>
                Schedule and location will be finalised soon.
            </>
        ),
        OriginLabel: () => <>Where is your origin?</>,
        OriginHelp: () => (
            <>
                We're only planning on having bus transportation from L'Ampolla
                and Tortosa. Reach out if you need help arranging transportation
                from other locations.
            </>
        ),
        SongsLabel: () => <>Song requests</>,
        SongsHelp: () => (
            <>Let us know which songs will keep you partying all night!</>
        ),
        WordsToUsLabel: () => <>Any words to us?</>,
        WordsToUsHelp: () => (
            <>Love!</>
        ),
        DinnerFormHeader: () => <>Welcome &ldquo;Pica-pica&ldquo;</>,
        DinnerGuestsLabel: () => <>Number of guests attending</>,
        DinnerGuestsHelp: () => (
            <>
                The number of guests (including yourself) that will be in
                attendance.
            </>
        ),
        AlertRSVPUpdated: () => <>Thanks for your RSVP!</>,
        GuestDetails: () => <>Guest Details</>,
        GuestNameLabel: "Fill in guest name",
        MarriageCeremonyHeader: () => <>Wedding Ceremony</>,
        MarriageCeremonyGuestsLabel: () => <>Number of guests attending</>,
    },
    [LANGUAGE.ZH]: {
        submitButtonText: "提交RSVP",
        updateButtonText: "更新RSVP",
        yesLabel: "是",
        noLabel: "否",
        WeddingFormHeader: () => <>宴會</>,
        NumberOfGuestsLabel: () => <>參加客人數量</>,
        Willyoubeattending: () => <>你會參加我們的婚禮嗎？</>,
        JoiningUsOptions: ["是的！我將參加證婚典禮和宴會。",
            "是的！我將參加證婚典禮。",
            "是的！我將參加宴會。",
            "很遺憾，我不能參加。"],
        zeroLabel: "Can't Attend",
        NumberOfGuestsHelp: () => (
            <>
                將參加宴會的客人數量（包括你自己）。
            </>
        ),
        TransportationToLabel: () => (
            <>我們正在安排去酒店的接駁車，請告訴我們你的選擇！</>
        ),
        TransportationFromLabel: () => (
            <>我們正在安排宴會後從酒店出發的接駁車，請告訴我們你的選擇！</>
        ),
        Transportationptions: [
            "我會自己安排！",
            "5:30pm (沒有)",
            "6:30pm (沒有)",
            "7:00pm"],

        TransportationpFromtions: [
            "我會自己安排！",
            "10:30pm",
            "11:00pm"],
        TransportationToHelp: () => (
            <>
                預計在下午5:30和6:30出發。
            </>
        ),
        TransportationFromHelp: () => (
            <>
                預計在晚上10:45出發。
            </>
        ),
        OriginLabel: () => <>Where is your origin?</>,
        OriginHelp: () => (
            <>
                We're only planning on having bus transportation from L'Ampolla
                and Tortosa. Reach out if you need help arranging transportation
                from other locations.
            </>
        ),
        SongsLabel: () => <>Song requests</>,
        SongsHelp: () => (
            <>Let us know which songs will keep you partying all night!</>
        ),
        WordsToUsLabel: () => <>有什麼跟我們說嗎？</>,
        WordsToUsHelp: () => (
            <>Love!</>
        ),
        DinnerFormHeader: () => <>Welcome &ldquo;Pica-pica&ldquo;</>,
        DinnerGuestsLabel: () => <>參加的客人數量</>,
        DinnerGuestsHelp: () => (
            <>
                將參加活動的客人數量（包括你自己）。
            </>
        ),
        AlertRSVPUpdated: () => <>Thanks for your RSVP!</>,
        GuestDetails: () => <>客戶細節</>,
        GuestNameLabel: "客人名稱",
        MarriageCeremonyHeader: () => <>婚禮儀式</>,
        MarriageCeremonyGuestsLabel: () => <>參加的客人數量</>,
    },
}
