import React, { useContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { object, number, string, array } from "yup"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import { Formik } from "formik"
import isUndefined from "lodash/isUndefined"
import get from "lodash/get"
import range from "lodash/range"

import { selectLanguage } from "utilities/cookies"
import { Store } from "store"
import { fetchUserRSVPInformation, putUserRSVPInformation, Record } from "actions"
import { rsvpForm } from "content/RSVP"

import styles from "../Forms.module.scss"
import { forEach } from "lodash"

const schema = object({
    joinOption: array().of(string()),
    words: string(),
    needBusToOption: array().of(string()),
    needBusFromOption: array().of(string()),
})

const YES = "Yes"
const NO = "No"


function RSVPForm() {
    const { state, dispatch } = useContext(Store)
    const [cookies] = useCookies(["language"])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const {
        app: {
            user: { username, credentials, userDetail },
            rsvp: { record },
        },
    } = state
    const {
        submitButtonText,
        updateButtonText,
        NumberOfGuestsHelp,
        WordsToUsLabel,
        WordsToUsHelp,
        AlertRSVPUpdated,
        TransportationToLabel,
        TransportationToHelp,
        TransportationFromLabel,
        TransportationFromHelp,
        Willyoubeattending,
        JoiningUsOptions,
        Transportationptions,
        TransportationpFromtions,
    } = rsvpForm[selectLanguage(cookies)]
    useEffect(() => {
        if (credentials) {
            fetchUserRSVPInformation(credentials, dispatch)
        }
    }, [credentials, dispatch])

    const submitForm = (values, actions) => {
        const { setSubmitting, setStatus } = actions
        console.log("try to sumit")
        console.log(values)
        putUserRSVPInformation(
            username,
            credentials,
            values,
            setSubmitting,
            setStatus,
            setShowConfirmation,
            dispatch
        )
    }


    // if (isNull(allowed)) {
    //     return (
    //         <Container>
    //             <Col className={styles.intro}>
    //                 <Alert variant="info">
    //                     <AlertNoEmail />
    //                 </Alert>
    //             </Col>
    //         </Container>
    //     )
    // }
    let fillingForUsers = [username]
    if (userDetail && userDetail.companion) {
        fillingForUsers = fillingForUsers.concat(userDetail.companion)
    }
    console.log(fillingForUsers)
    // wedding values
    // const weddingMaxGuests = get(allowed, ["Wedding", "MaxGuests"])
    let savedItem = record ? record : new Record()
    console.log("!!!!!!!")
    console.log(JoiningUsOptions)
    console.log(savedItem)
    // set default values for options
    for (let i in fillingForUsers) {
        if (isUndefined(savedItem.joinOption[i])) {
            savedItem.joinOption[i] = 0
        }
        if (isUndefined(savedItem.needBusToOption[i])) {
            savedItem.needBusToOption[i] = 0
        }
        if (isUndefined(savedItem.needBusFromOption[i])) {
            savedItem.needBusFromOption[i] = 0
        }
    }
    const buttonText = savedItem.cognitoUserid
        ? submitButtonText
        : updateButtonText
    return (
        <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={savedItem}
            onSubmit={submitForm}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isSubmitting,
                errors,
                status,
            }) => (
                <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    {/* <div className="text-center">
                        <h5 className="text-muted">
                            <WeddingFormHeader />
                        </h5>
                    </div> */}
                    {
                        fillingForUsers.map((user, index) => {
                            return <> {index !== 0 && <div><b>For {user}</b></div>}
                                <Form.Group controlId={`joinOption${index}`}>
                                    <Form.Label>
                                        <Willyoubeattending />
                                    </Form.Label>
                                    <Form.Control
                                        name={`joinOption[${index}]`}
                                        as="select"
                                        value={values.joinOption[index]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            touched.joinOption && errors.joinOption
                                        }
                                    >
                                        {
                                            JoiningUsOptions.map((option, index) => {
                                                return <option key={index} value={index}>{option}</option>
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        <NumberOfGuestsHelp />
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="controlIdNeedBus">
                                    <Form.Label>
                                        <TransportationToLabel />
                                    </Form.Label>
                                    <Form.Control
                                        name={`needBusToOption[${index}]`}
                                        as="select"
                                        value={values.needBusToOption[index]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.needBusToOption && errors.needBusToOption}
                                    >
                                        {
                                            Transportationptions.map((option, index) => {
                                                return <option key={index} value={index}>{option}</option>
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        <TransportationToHelp />
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="controlIdNeedBus">
                                    <Form.Label>
                                        <TransportationFromLabel />
                                    </Form.Label>
                                    <Form.Control
                                        name={`needBusFromOption[${index}]`}
                                        as="select"
                                        value={values.needBusFromOption[index]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.needBusFromOption && errors.needBusFromOption}
                                    >
                                        {
                                            TransportationpFromtions.map((option, index) => {
                                                return <option key={index} value={index}>{option}</option>
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        <TransportationFromHelp />
                                    </Form.Text>
                                </Form.Group>
                            </>
                        })
                    }
                    <Form.Group controlId="controlIdWeddingWords">
                        <Form.Label>
                            <WordsToUsLabel />
                        </Form.Label>
                        <Form.Control
                            name="words"
                            as="textarea"
                            rows="3"
                            value={values.words}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.words && errors.words}
                        />
                        <Form.Text className="text-muted">
                            <WordsToUsHelp />
                        </Form.Text>
                    </Form.Group>

                    <Button
                        className="mt-5"
                        variant="primary"
                        type="submit"
                        size="lg"
                        disabled={isSubmitting}
                        block
                    >
                        {buttonText}
                    </Button>
                    {status && (
                        <Alert variant="danger" className="mt-4">
                            {status}
                        </Alert>
                    )}
                    <Alert
                        variant="success"
                        className="mt-4"
                        onClose={() => setShowConfirmation(false)}
                        show={showConfirmation}
                    >
                        <AlertRSVPUpdated />
                    </Alert>
                </Form>
            )}
        </Formik>
    )
}

export default RSVPForm
