import React, { useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import {createRoot} from "react-dom/client"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { Amplify } from 'aws-amplify';
import { CookiesProvider } from "react-cookie"
import get from "lodash/get"
import isNull from "lodash/isNull"

import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"

import { Authentication } from "views/Authentication"
import { AppLayout } from "views/AppLayout"
import { initializeApp } from "actions"
import { LANGUAGE } from "actions/constants"

import { StoreProvider, Store } from "./store"
import * as serviceWorker from "./serviceWorker"

// to init emplify sdk
Amplify.configure({
    Auth: {
        Cognito: {
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT,
            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification

            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_REGION,

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_USER_POOL,


            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,

            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            signUpVerificationMethod: 'code', // 'code' | 'link'
        },
    }
});

const App = () => {
    // custom store provider
    const { dispatch } = useContext(Store)

    const [cookies, setCookie] = useCookies(["language"])
    const language = get(cookies, ["language"], null)
    const queryParameters = new URLSearchParams(window.location.search)
    const setLang = queryParameters.get("lang")
    // const searchParams = new URLSearchParams(search);
    // const param = searchParams.get("signcode");
    useEffect(() => {
        initializeApp(dispatch)
        if (isNull(language) && setLang && setLang === "zh"){
            setCookie("language", LANGUAGE.ZH, { path: "/" })
        } else if (isNull(language)) {
            setCookie("language", LANGUAGE.EN, { path: "/" })
        }
    }, [dispatch, setCookie, language])

    return (
        <BrowserRouter>
            <Switch>
                <Route path={"/auth"} component={Authentication} />
                <Route component={AppLayout} />
            </Switch>
        </BrowserRouter>
    )
}

const root = createRoot(document.getElementById("root"));
root.render(
    <CookiesProvider>
        <StoreProvider>
            <App />
        </StoreProvider>
    </CookiesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (process.env.NODE_ENV === "production"){
    console.log = () => {}
}
