import React from "react"
import { useCookies } from "react-cookie"

import { selectLanguage } from "utilities/cookies"
import { headers } from "content/Home"
import Carousel from 'react-bootstrap/Carousel';

import {headerImages} from "photos/header"
import styles from "./Header.module.scss"
import classNames from "classnames"

function Header() {
    const [cookies] = useCookies(["language"])
    const HeaderContent = headers[selectLanguage(cookies)]
    return (
        <Carousel indicators={false}>
            {headerImages.map((image, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={image}
                        alt="photos"
                    />
                    <Carousel.Caption className={classNames("carousel-caption", styles.carousel_caption)}>
                        <p className={styles.caption}>{HeaderContent.Title}</p>
                        {/* <p>{HeaderContent.subtitle}</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default Header
