import React from "react"

import { LANGUAGE } from "actions/constants"


export default {
    [LANGUAGE.EN]: {
        Title: () => <span>Tammy and Roy are getting married!</span>,
        Date: () => (
            <div className="d-flex flex-column">
                <div>
                    <p>Friday</p>
                    <p>06/12/2024</p>
                    {/* <span >
                        06/12/2024
                    </span> */}
                </div>
                {/* <div>Love is patient...</div> */}
                <a href="https://maps.app.goo.gl/47Mbu24XSAPrpA1o8" target="_blank" rel="noopener noreferrer" style={{color:"#000000"}}>Auberge Discovery Bay<br/>Hong Kong</a>
            </div>
        ),
        Location: () => <a href="https://maps.app.goo.gl/47Mbu24XSAPrpA1o8" target="_blank" rel="noopener noreferrer">Auberge Discovery Bay, Hong Kong</a>,
        locationLink: "https://goo.gl/maps/93vJLAEHGgZwUvET9",
        RespondPlease: "Please respond by June 30, 2024",
    },
    [LANGUAGE.ZH]: {
        Title: () => <span>芷彥和民山要結婚了！</span>,
        Date: () => (
            <div className="d-flex flex-column">
                <div>
                    <p><b>星期五</b></p>
                    <p><b>2024年12月06日</b></p>
                    {/* <span >
                        06/12/2024
                    </span> */}
                </div>
                {/* <div>Love is patient...</div> */}
                <a href="https://maps.app.goo.gl/47Mbu24XSAPrpA1o8" target="_blank" rel="noopener noreferrer" style={{color:"#000000"}}><b>香港愉景灣酒店</b></a>
            </div>
        ),
        Location: () => <a href="https://maps.app.goo.gl/47Mbu24XSAPrpA1o8" target="_blank" rel="noopener noreferrer">Auberge Discovery Bay, Hong Kong</a>,
        locationLink: "https://goo.gl/maps/93vJLAEHGgZwUvET9",
        RespondPlease: "請在2024年6月30日前回覆",
    },
}
