import React from "react"
import { Link } from "react-router-dom"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Header: () => <>Please Sign Up</>,
        namePlaceholder: "Name",
        emailPlaceholder: "Email",
        EmailHelp: () => (
            <>
               
            </>
        ),
        passwordPlaceholder: "Password",
        SubmitButton: () => <>Sign Up</>,
        SubmitButtonLoading: () => <>Signing Up...</>,
        HaveAccountPrompt: () => (
            <>
                Already have an account? <Link to="/auth">Sign in here.</Link>
            </>
        ),
    },
    [LANGUAGE.ZH]: {
        Header: () => <>請註冊</>,
        namePlaceholder: "名字",
        emailPlaceholder: "電子郵件",
        EmailHelp: () => (
            <></>
        ),
        passwordPlaceholder: "密碼",
        SubmitButton: () => <>註冊</>,
        SubmitButtonLoading: () => <>正在註冊...</>,
        HaveAccountPrompt: () => (
            <>
                 已經有帳戶了嗎？ <Link to="/auth">在這裡登入。</Link>
            </>
        ),
    },
}
