import React from "react"

import { LANGUAGE } from "actions/constants"

const hashtag = "lledoisalim"

export default {
    [LANGUAGE.EN]: {
        Title: () => <span>Tammy and Roy are getting married!</span>,
        Date: () => (
            <div className="d-flex flex-column">
                <div>
                    <span >
                        06/12/2024
                    </span>
                </div>
                <div>Love is patient...</div>
            </div>
        ),
        Location: () => <span>Auberge Discovery Bay, Hong Kong</span>,
        locationLink: "https://goo.gl/maps/93vJLAEHGgZwUvET9",
        hashtag,
    },
    [LANGUAGE.ZH]: {
        Title: () => <span>芷彥和民山要結婚了！</span>,
        Date: () => (
            <div className="d-flex flex-column">
                <div>
                    <span >
                        06/12/2024
                    </span>
                </div>
                <div>愛是有耐心的...</div>
            </div>
        ),
        Location: () => <span>香港愉景灣酒店</span>,
        locationLink: "https://goo.gl/maps/93vJLAEHGgZwUvET9",
        hashtag,
    },
}
